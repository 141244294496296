import Vue from 'vue'
import request from './axios'

// 研究列表-查增删
export function getResearch(params) { //
    return request({
        url: '/api/tSiteResearch/lookForAllByUserId',
        method: 'GET',
        params,
    })
}

export function addResearch(data) { //
    return request({
        url: '/api/tSiteResearch/saveBean',
        method: 'POST',
        data,
    })
}

// export function deleteResearch(params) { //
//     return request({
//         url: '/api/tSiteResearch/deleteBeanById',
//         method: 'POST',
//         params,
//     })
// }
export function deleteResearch(data){
    return request({
        url:'/api/tSiteResearch/updateDeletedByCode',
        method:'POST',
        data
    })
}


