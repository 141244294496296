<template>
    <div id="research" v-if="!loading">
        <div class="research-content">
            <el-scrollbar style="height:100%">
                <div v-show="!dataList.length" class="no-research">
                    <img alt="" src="../../public/img/icons/icon.png"/>
                    <p class="no-research_des">啊哦，您还没有研究</p>
                    <!-- <el-button class="no-research_btn" size="mini" type="primary" @click="researchAdd"
                                v-authorizeType:research_add>新建研究
                    </el-button> -->
                    <el-link icon="el-icon-back" :underline="false" @click="$router.replace('/')">研究列表</el-link>
                </div>
                <div v-show="dataList.length" class="has-research_btn">
                    <el-link icon="el-icon-back" :underline="false" style="font-size:16px" @click="$router.replace('/')">回收站</el-link>
                    <div class="right" style="float:right">
                        <!-- <el-link :underline="false" class="link">回收站</el-link> -->
                        <el-button class="el-icon-s-unfold" :class="{active:isRow}" size="mini"
                                   @click="changeLayout(true)"></el-button>
                        <el-button class="el-icon-menu" size="mini" :class="{active:!isRow}"
                                   @click="changeLayout(false)" style="margin-left:0"></el-button>
                    </div>
                </div>
                <div v-show="dataList.length && isRow" class="has-research">
                    <el-scrollbar class="page-component__scroll">
                        <el-table :data="dataList" size="small" style="width: 100%" :show-header="false">
                            <el-table-column prop="researchName" label="研究标记">
                                <template slot-scope="scope">
                                    <p style="margin-left: 10px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                        <i class="el-icon-price-tag" style="margin-right:5px"></i>{{scope.row.researchName}}
                                    </p>
                                    <span style="padding-left:33px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                                          class="title">研究编号:  {{ scope.row.code }}</span>
                                    <span class="title">研究类型:  {{ scope.row.researchType == 0?'干预型':'观察型'}}</span>
                                    <span class="title">样本量:  {{ scope.row.expectCaseNum }}</span>
                                    <span style="font-size:14px;color:#212121;font-weight: 500;padding-left:33px;white-space: nowrap;">研究周期:  {{ scope.row.startDate.substring(0,10).replace(/-/g,'/') }}-{{scope.row.endDate.substring(0,10).replace(/-/g,'/') }}</span>
                                    <!-- <div class="environment"> -->
                                    <!-- v-if="scope.row.environment == 0|| scope.row.environment == 1" -->
                                    <!-- <el-button type="text" class="el-icon-monitor"
                                               :class="{'iDisabled':scope.row.publish==0||scope.row.publish==2  ||scope.row.environment == 2 }"
                                               @click="toTestee(scope.row,'test')" style="margin-right:30px">测试环境
                                    </el-button> -->
                                    <!-- v-if="scope.row.environment == 0||scope.row.environment == 2" -->
                                    <!-- <el-button type="text" class="el-icon-monitor"
                                               :class="{'iDisabled':scope.row.publish==0||scope.row.publish==1 ||scope.row.environment == 1 }"
                                               @click="toTestee(scope.row,'prod')" style="margin-right:30px">生产环境
                                    </el-button> -->
                                    <!-- </div> -->
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="200px">
                                <template slot-scope="scope">
                                    <el-popconfirm
                                            title="您确定要删除该研究吗？"
                                            @confirm="remove(scope.row,2)"
                                            :class="{'iDisabled':scope.row.ascriptionType==1}"
                                            style="float:right;margin-right:10px;height:60px"
                                            v-authorizeType:research_del
                                    >
                                        <i slot="reference" class="el-icon-delete i-fontSize iconStyle"
                                           :class="{'iDisabled':scope.row.ascriptionType==1}" title="删除"></i>
                                    </el-popconfirm>
                                    <el-popconfirm
                                            title="您确定要还原该研究吗？"
                                            @confirm="remove(scope.row,0)"
                                            :class="{'iDisabled':scope.row.ascriptionType==1}"
                                            style="float:right;margin-right:10px;height:60px"
                                            v-authorizeType:research_del
                                    >
                                        <i slot="reference" class="el-icon-refresh i-fontSize iconStyle"
                                           :class="{'iDisabled':scope.row.ascriptionType==1}" title="还原"></i>
                                    </el-popconfirm>
                                    <!-- <div class="btn"
                                         style="height:40px;margin-top:60px;padding-right:10px;text-align:right;">
                                        <el-button size="mini" class="ssetting" @click="toSetting(scope.row)"
                                                   title="研究配置" type="primary" v-authorizeType:research_setting>设置
                                        </el-button>
                                        <el-button size="mini" class="ssetting" @click="toVisit(scope.row)" title="访视"
                                                   type="primary"  v-authorizeType:research_design>设计
                                        </el-button>
                                    </div> -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-scrollbar>
                    <el-pagination class="research-pagination" @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange" :current-page="pagination.currentPage"
                                   :page-sizes="[10, 20, 30, 40]" :page-size="pagination.size"
                                   layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
                    </el-pagination>
                </div>
                <div class="flex" v-show="dataList.length && !isRow">
                    <el-row :gutter="10">
                        <el-col :span="6" v-for="(item,index) in dataList" :key="index">
                            <el-card class="box-card">
                                <div class="text item">
                                    <div class="top">
                                        <div class="icon"><i class="el-icon-price-tag"></i></div>
                                        <div class="title" style="padding-left:0">
                                            {{item.researchName}}
                                        </div>
                                        <div class="delete delete_re"  v-authorizeType:research_del>
                                            <el-popconfirm
                                                    title="您确定要还原该研究吗？"
                                                    @confirm="remove(item,0)"
                                                    :class="{'iDisabled':item.ascriptionType==1}"
                                                    style="float:left;margin-right:10px"
                                            >
                                                <i slot="reference" class="el-icon-refresh i-fontSize iconStyle"
                                                   :class="{'iDisabled':item.ascriptionType==1}" title="还原"></i>
                                            </el-popconfirm>
                                            <el-popconfirm
                                                    title="您确定要删除该研究吗？"
                                                    @confirm="remove(item,2)"
                                                    :class="{'iDisabled':item.ascriptionType==1}"
                                                    style="float:left;"
                                            >
                                                <i slot="reference" class="el-icon-delete i-fontSize iconStyle"
                                                   :class="{'iDisabled':item.ascriptionType==1}" title="删除"></i>
                                            </el-popconfirm>

                                        </div>
                                    </div>
                                    <div class="content">
                                        <div><span>研究编号：{{item.code}}</span></div>
                                        <div><span>研究类型：{{item.researchType == 0?'干预型':'观察型'}}</span></div>
                                        <div><span>样本量：{{item.expectCaseNum}}</span></div>
                                        <div><span>研究周期：{{item.startDate.substring(0,10).replace(/-/g,'/')}}-{{item.endDate.substring(0,10).replace(/-/g,'/')}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="bottom"> -->
                                    <!-- v-if=" item.environment == 0 || item.environment == 1"-->
                                    <!-- <el-button type="text"  class="el-icon-monitor"
                                               :class="{'iDisabled':item.publish==0||item.publish==2 ||item.environment == 2}"
                                               @click="toTestee(item,'test')" style="margin-right:30px">测试环境
                                    </el-button> -->
                                    <!-- v-if=" item.environment == 0 || item.environment == 2" -->
                                    <!-- <el-button type="text" class="el-icon-monitor"
                                               :class="{'iDisabled':item.publish==0||item.publish==1 || item.environment == 1}"
                                               @click="toTestee(item,'prod')" style="margin-right:30px">生产环境
                                    </el-button> -->
                                    <!-- </div> -->
                                    <!-- <div class="setting" > -->
                                    <!-- <el-button  size="mini" class="ssetting" @click="toSetting(item)" title="研究配置"
                                                type="primary" v-authorizeType:research_setting>设置
                                    </el-button> -->
                                    <!-- <el-button size="mini" class="ssetting" @click="toManage(item)" title="研究配置">设置</el-button> -->
                                    <!-- <el-button size="mini" class="ssetting" @click="toVisit(item)" title="访视"
                                               type="primary" v-authorizeType:research_design>设计
                                    </el-button> -->
                                    <!-- </div> -->
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-scrollbar>
        </div>
        <!-- <div v-if="dialogVisible">
            <el-dialog title="新建研究" :visible.sync="dialogVisible" width="40%" custom-class="research-dialog"
                       @close="$refs.researchForm.resetFields()"
                       @open="submitButtonDisabled = false">
                <el-form size="mini" ref="researchForm" :model="researchForm" :rules="researchRules" label-width="80px">
                    <el-form-item label="研究标题" prop="title">
                        <el-input v-model="researchForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="研究编号" prop="id">
                        <el-input v-model="researchForm.id"></el-input>
                    </el-form-item>
                    <el-form-item label="研究摘要" prop="abstract">
                        <el-input type="textarea" v-model="researchForm.abstract"></el-input>
                    </el-form-item>
                    <el-form-item label="研究类型" prop="type">
                        <el-select :style="{'width':'100%'}" v-model="researchForm.type" placeholder="请选择研究类型">
                            <el-option label="干预型" :value="0"></el-option>
                            <el-option label="观察型" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="样本量" prop="amount">
                        <el-input type="number" v-model="researchForm.amount" min="1" max="99999"></el-input>
                    </el-form-item>
                    <el-form-item label="研究周期" prop="dataStart">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择开始日期" value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="researchForm.dataStart" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col :span="2" :style="{'text-align':'center','color':'#c4c4c4'}">—</el-col>
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择结束日期" value-format="yyyy-MM-dd HH:mm:ss"
                                            v-model="researchForm.dataEnd" style="width: 100%;"></el-date-picker>
                        </el-col>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisible = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="addConfirm('researchForm')"
                           :disabled="submitButtonDisabled">确 定</el-button>
			</span>
            </el-dialog>
        </div> -->
    </div>

</template>

<script>
    import {Loading} from 'element-ui';
    import {getResearch, addResearch, deleteResearch} from "@/api/research";
    import globalConst from '../utils/globalConst'

    let loadingInstance = null;
    export default {
        name: 'Research',
        data() {

            //新增研究时，时间区间校验处理（prop只支持绑定一个String）
            // const timeSection = (rule, value, callback) => {
            //     if (!value) {
            //         return callback(new Error('请输入开始时间'));
            //     } else if (!this.researchForm.dataEnd) {
            //         return callback(new Error('请输入结束时间'));
            //     } else if (this.researchForm.dataEnd <= value) {
            //         return callback(new Error('结束时间需大于开始时间'));
            //     } else {
            //         callback();
            //     }
            // };

            // let amountValid = (rule, value, callback) => {
            //     if (value === '') {
            //         callback(new Error('请输入样本量!'));
            //     } else if (value > 99999) {
            //         callback(new Error('样本量最大为99999!'));
            //     } else
            //         callback();
            // };

            return {
                loading: true,
                dialogVisible: false,
                hasResearch: false,
                isRow: true,
                dataList: [],
                pagination: {
                    currentPage: 1,
                    total: 0,
                    size: 10
                },
                // researchForm: {
                //     title: '',
                //     id: '',
                //     abstract: '',
                //     type: 0,
                //     amount: 1,
                //     dataStart: '',
                //     dataEnd: '',
                // },

                // researchRules: {
                //     title: [
                //         {required: true, message: '请输入研究标题', trigger: 'blur'},
                //         {min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur'}
                //     ],
                //     id: [
                //         {required: true, message: '请输入研究编号', trigger: 'blur'},
                //         {pattern: /^[A-Za-z0-9]{1,12}$/, message: '请输入 12 位以内数字或字母'}
                //     ],
                //     abstract: [
                //         {required: true, message: '请输入研究摘要', trigger: 'blur'},
                //         {min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur'}
                //     ],
                //     type: [
                //         {required: true, message: '请选择研究类型'}
                //     ],
                //     amount: [
                //         {required: true, message: '请输入样本量', trigger: 'blur'},
                //         {validator: amountValid, trigger: 'change'},
                //         // {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
                //     ],
                //     dataStart: [
                //         {required: true, validator: timeSection, trigger: 'change,blur'}
                //     ],
                // },
                submitButtonDisabled: false,
            }
        },
        created() {
            loadingInstance = Loading.service({fullscreen: true, text: 'Loading',});
            //初始数据源
            this.$localData('set', 'operatingEnv', 'test');
            this.getResearchList();
        },
        methods: {
            //改变页面布局
            changeLayout(value) {
                this.isRow = value
            },
            //获取研究列表
            getResearchList() {
                const getData = {
                    pageIndexs: this.pagination.currentPage,
                    pageIndexsCont: this.pagination.size,
                    deleted:1
                }
                getResearch(getData)
                    .then(res => {
                        this.dataList = res.data.dataList;
                        this.pagination.total = res.data.count;
                        loadingInstance.close();
                        this.loading = false;
                    })
                    .catch(error => {
                        loadingInstance.close();
                        this.loading = false;
                    });
            },
            researchAdd() {
                this.dialogVisible = true;
                this.$refs.researchForm && this.$refs.researchForm.clearValidate();
            },
            // addConfirm(formName) {
            //     this.$refs[formName].validate((valid) => {
            //         if (valid) {
            //             this.submitButtonDisabled = true;
            //             const postData = {
            //                 code: this.researchForm.id,
            //                 researchName: this.researchForm.title,
            //                 researchTarget: this.researchForm.abstract,
            //                 expectCaseNum: this.researchForm.amount,
            //                 researchType: this.researchForm.type,
            //                 startDate: this.researchForm.dataStart,
            //                 endDate: this.researchForm.dataEnd,
            //                 createBy: this.$loginName,			//登录之后获取到的参数
            //                 // status: 0,
            //             };
            //             addResearch(postData)
            //                 .then(res => {
            //                     this.getResearchList();
            //                     this.dialogVisible = false;
            //                 })
            //                 .catch(error => {
            //                     this.submitButtonDisabled = false;
            //                 });
            //         } else {
            //             console.log('error submit!!');
            //             return false;
            //         }
            //     });
            // },
            remove(data,value) {
                const postData = {
                    researchCode: data.researchCode,
                    deleted:value
                };
                deleteResearch(postData)
                    .then(res => {
                        this.$message.success('操作成功')
                        this.getResearchList();
                    })
                    .catch(error => {

                    });
            },
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getResearchList();
            },
            handleCurrentChange(pageNum) {
                this.pagination.currentPage = pageNum;
                this.getResearchList();
            },
            //to访视
            // toVisit(data) {
            //     this.$router.push({
            //         name: 'Visit',
            //         query: {
            //             code: data.researchCode,
            //             name: data.researchName,
            //             release: data.publish,
            //             versionCode: data.versionCode
            //         },
            //     });
            // },
            // //to受试者
            // toTestee(data, env) {
            //     if (env == 'prod') {
            //         window.location.href = globalConst.prodServerUrl +
            //             "?env=prod&researchCode=" + data.researchCode + "&researchName=" + encodeURIComponent(data.researchName)+"&environment="+data.environment
            //     } else
            //         this.$router.push({
            //             name: 'Testee',
            //             query: {researchCode: data.researchCode, researchName: data.researchName,env:env,environment:data.environment}
            //         })
            // },
            // //to中心用户管理
            // toManage(row) {
            //     this.$router.push({
            //         name: 'Manage',
            //         query: {researchCode: row.researchCode, researchName: row.researchName}
            //     })
            // },
            // //to研究设置
            // toSetting(row) {
            //     this.$router.push({name: 'Setting', query: row})
            // }
        },
        beforeRouteLeave(to,from,next){
            loadingInstance && loadingInstance.close();
             next()
        }
    }
</script>

<style lang="scss" scoped>
    .delete_re {
        display: flex;
    }

    //点击
    .active {
        background: #2c5c89 !important;
        color: #fff !important;
    }

    .icon {
        i {
            font-size: 20px;
        }
    }

    p {
        margin-bottom: 10px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(33, 33, 33, 1);
        line-height: 40px;

        i {
            font-size: 20px;
        }
    }

    .right {
        .el-button {
            width: 50px;
            height: 32px;
            font-size: 20px;
            line-height: 17px;
            color: #fff;
            background: #ecf5ff;
        }

        .el-icon-s-unfold:before {
            width: 18px;
            display: block;

        }

        .el-icon-s-unfold {}
    }

    .btn {
        .el-button {
            border: none;
        }
    }

    .el-button--primary {
        background: #2c5c89;
        color: #FFF;
        border: none;
    }

    .el-card__body {
        padding: 10px;
    }

    .el-scrollbar__wrap.default-scrollbar__wrap {
        overflow-x: auto;
    }

    //
    .box-card {
        width: 100%;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 10px;

        .top {
            display: flex;
            height: 45px;
            text-overflow: ellipsis;
            overflow: hidden;

            .icon {
                margin-right: 10px;
            }

            .title {
                display: inline-block;
                width: 280px;
                height: 40px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: Medium;
                color: rgba(33, 33, 33, 1);
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .content {
            margin-left: 28px;

            div {
                width: 100%;
                height: 30px;

                span {
                    display: inline-block;
                    width: 100%;
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #787878;
                    line-height: 30px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        .bottom {
            margin-left: 28px;
            margin-bottom: 10px;

            /deep/ .el-button {
                span {
                    margin-left: 5px;
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 500;
                }
            }
        }

        .setting {
            text-align: right;
            margin-top: 20px;
        }

        .el-button {
            border: none;
        }
    }

    .page-component__scroll {
        height: 100%;
    }

    .page-component__scroll .el-scrollbar__wrap {
        overflow-x: auto;
    }

    .ssetting {
        margin-bottom: 3px;
        background: #2c5c89;
        color: #fff;
    }

    p {
        font-family: PingFang-SC-Medium;
        font-weight: 500;
    }

    .title {
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        display: inline-block;
        width: 200px;
        color: #212121;
        font-size: 14px;
        vertical-align: top;
        padding-left: 33px;
    }

    .environment {
        padding-left: 30px;
        box-sizing: border-box;

        .el-button {
            font-size: 14px;
        }

        .el-icon-monitor:before {
            margin-right: 10px;
        }
    }

    #research {
        height: 100%;

        // background: rgba(73, 150, 255, 0.14);
        .research-content {
            position: absolute;
            top: 60px;
            bottom: 16px;
            left: 16px;
            right: 16px;
            background: #fff;
            overflow-y: auto;

            .has-research_btn {
                text-align: left;
                // background: rgba(73, 150, 255, 0.14);
                border-bottom: 1px solid #ededed;
                padding-bottom: 14px;
                min-height: 26px;
            }

            .no-research {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                .no-research_des {
                    width: 220px;
                    height: 24px;
                    font-size: 24px;
                    color: #96b0c3;
                    line-height: 24px;
                    margin: 16px auto 54px;
                }

                .no-research_btn {
                    width: 220px;
                    height: 42px;
                    background: #2c5c89;
                    border-radius: 4px;
                    color: #fff;
                }
            }

            .has-research {
                position: absolute;
                top: 44px;
                bottom: 50px;
                left: 0;
                right: 0;
                overflow-y: auto;

                .iconStyle {
                    cursor: pointer;
                    font-size: 18px;
                    color: #e5e5e5;
                    margin-left: 10px;

                    &:hover {
                        color: #2c5c89;
                        cursor: pointer;
                    }
                }

                .research-pagination {
                    position: fixed;
                    z-index: 15;
                    bottom: 24px;
                    right: 32px;
                }
            }
        }
    }

    .link {
        margin-right: 20px;
        font-size: 12px;
        color: #2c5c89;
    }
</style>
<style>
    .el-dialog.research-dialog {
        min-width: 420px;
    }

    .el-table .cell {
        line-height: 32px;
    }
</style>
